<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
      alt="page-not-found"
    />

    <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree-4.png"
    ></v-img>

    <div class="page-title text-center px-4">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">{{ t('error_404.page_not_found') }}</span>
        <v-icon color="warning">
          {{ icons.mdiAlert }}
        </v-icon>
      </h2>
      <p class="text-sm">
        {{ t('error_404.error_message') }}
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="700"
          src="@/assets/images/3d-characters/error.png"
        ></v-img>
      </div>

      <v-btn
        color="primary"
        to="/"
        class="mb-4"
      >
        {{ t('error_404.back_home') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
/* eslint-disable vuejs-accessibility/alt-text */
import { mdiAlert } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { loadLanguageAsync } from '@/plugins/i18n'
import { onMounted } from '@vue/composition-api'

export default {
  setup() {
    const { t } = useUtils()

    onMounted(async () => {
      // loadLanguageAsync(navigator.language.includes('en') ? 'en' : 'es' || 'en')
      loadLanguageAsync('es')
    })

    return {
      t,
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
